export default function Video(props: React.ComponentProps<"svg">) {
    return (
        <svg
            viewBox="0 0 26 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M18.4661 5.14498C18.4908 5.13469 18.5059 5.13156 18.5173 5.12328C19.4136 4.46431 20.3123 3.80836 21.2045 3.14375C21.5182 2.91005 21.8385 2.69466 22.2228 2.59844C22.932 2.42094 23.6017 2.50486 24.2213 2.90967C24.7429 3.25038 25.087 3.7213 25.257 4.3188C25.3331 4.58637 25.3495 4.86223 25.3495 5.13883C25.35 7.64811 25.35 10.1573 25.3499 12.6664C25.3499 13.1892 25.2524 13.6877 24.9577 14.1301C24.562 14.7237 24.0065 15.0817 23.3079 15.2167C22.5685 15.3596 21.9155 15.1462 21.3234 14.7136C20.3936 14.0341 19.4683 13.3486 18.5409 12.6658C18.5231 12.6526 18.5034 12.6418 18.4657 12.618C18.4657 12.6844 18.4659 12.7323 18.4657 12.7802C18.4644 13.2255 18.4823 13.6721 18.4576 14.1162C18.4048 15.0681 18.0011 15.8508 17.241 16.4314C16.6768 16.8624 16.0271 17.0628 15.3161 17.0626C11.952 17.0621 8.58793 17.062 5.22375 17.0618C4.71532 17.0616 4.20664 17.0672 3.69821 17.0603C2.231 17.0403 0.959362 15.958 0.707468 14.5123C0.670964 14.303 0.653778 14.0877 0.653527 13.8751C0.650265 10.5374 0.649011 7.19952 0.652398 3.8618C0.653276 2.93476 0.985078 2.14132 1.67478 1.51359C2.18973 1.04493 2.79964 0.764684 3.49749 0.727678C4.06187 0.697697 4.62863 0.709238 5.19439 0.709112C8.56372 0.707858 11.9331 0.710994 15.3024 0.707231C16.224 0.706102 17.0034 1.03151 17.6347 1.69586C18.0751 2.15938 18.3414 2.71699 18.4287 3.35187C18.5065 3.91687 18.4666 4.48576 18.4661 5.05328V5.14498Z" />
        </svg>
    )
}
