import { PlayerViewRemote } from "@/types/PlayerView"

export type PlayerAction = {
    action: "SET" | "CREATE" | "EDIT" | "IMG" | "DELETE"
    playerArr?: PlayerViewRemote[]
    player?: PlayerViewRemote
    partialPlayer?: Partial<PlayerViewRemote>
    imgData?: {
        id: number
        img: string
    }
    editCallback?: (player: PlayerViewRemote) => void
}

export const playerReducer = (
    players: PlayerViewRemote[],
    action: PlayerAction
) => {
    switch (action.action) {
        case "SET":
            return action.playerArr !== undefined ? [...action.playerArr] : []
        case "CREATE":
            if (action.player) players.push(action.player)
            return [...players]
        case "EDIT": {
            if (action.partialPlayer === undefined) return players

            const playerIndex = players.findIndex(
                (p) => action.partialPlayer && p.id === action.partialPlayer.id
            )
            if (playerIndex === -1) return players

            players[playerIndex] = {
                ...players[playerIndex],
                ...action.partialPlayer,
            }
            if (action.editCallback !== undefined)
                action.editCallback(players[playerIndex])
            return [...players]
        }
        case "IMG": {
            if (!action.imgData) {
                return players
            }
            const index = players.findIndex((p) => p.id === action.imgData?.id)
            if (index > -1) {
                players[index] = {
                    ...players[index],
                    img: action.imgData.img,
                }
                return [...players]
            }
            return players
        }
        case "DELETE": {
            if (
                action.partialPlayer !== undefined &&
                action.partialPlayer.id !== undefined
            ) {
                const deleteIndex = players.findIndex(
                    (player) =>
                        action.partialPlayer &&
                        player.id === action.partialPlayer.id
                )
                if (deleteIndex !== -1) {
                    players.splice(deleteIndex, 1)
                    return [...players]
                }
            }
            return [...players]
        }
    }
}
