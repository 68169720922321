import { ComponentProps, useRef } from "react"
import useVideo from "@/hooks/VideoHook"

export interface VideoDisplayProps extends ComponentProps<"div"> {
    idStr?: string
}

export default function VideoDisplay({
    idStr,
    children,
    className,
    ...props
}: VideoDisplayProps) {
    const ref = useRef<HTMLDivElement>(null)

    useVideo(ref, idStr)

    return (
        <div
            className={`
                relative

                ${className}
            `}
            {...props}
        >
            <div
                ref={ref}
                className={`
                    absolute left-0 top-0 h-full w-full bg-cover bg-center
                `}
            />
            {children}
        </div>
    )
}
