import { ChangeEvent, ComponentProps, useState } from "react"
import Checkbox from "@/components/Checkbox"
import FormControl from "@mui/material/FormControl"
import { localDirectOnOffMessageV240124 } from "@/types/AppMessage"
import { useDeviceContext } from "@/context/DeviceContext"
import { useAgentContext } from "@/context/AgentContext"

export default function DirectAudioCheckbox(props: ComponentProps<"input">) {
    const { sendAgentMessage } = useAgentContext()
    const { settings, setSettings } = useDeviceContext()

    const [value, setValue] = useState(
        settings.directOnOff === "1" ? true : false
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        const tmp: string = checked ? "1" : "0"
        if (settings.directOnOff !== tmp) {
            setSettings({ directOnOff: tmp })
            sendAgentMessage(new localDirectOnOffMessageV240124(checked))
        }
        setValue(checked)
    }

    return (
        <FormControl>
            <Checkbox
                defaultChecked={props.defaultChecked}
                className="mt-2"
                onChange={handleChange}
                checked={value}
            />
        </FormControl>
    )
}
