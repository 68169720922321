import LoggerService from "@/services/LoggerService"
import { AgentMessage } from "@/types/AppMessage"
import {
    MessageEventListener,
    SocketEvent,
    SocketEventListener,
} from "@/types/WebSocket"
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"

type AgentContextProps = {
    sendAgentMessage: (message: AgentMessage) => boolean
    addMessageListeners: (...listeners: MessageEventListener[]) => void
    removeMessageListener: (
        listener: MessageEventListener
    ) => MessageEventListener | null
    notify: (event: SocketEvent) => void
    socketReady: boolean
}

const AgentContext = createContext<AgentContextProps | undefined>(undefined)

export const AgentContextProvider = ({ children }: PropsWithChildren) => {
    const logger = new LoggerService("WebSocket")
    const port = 50050

    const socketListeners = useRef<SocketEventListener[]>([])
    const messageListeners = useRef<MessageEventListener[]>([])

    const socket = useRef<WebSocket | null>(null)

    const [socketReady, setSocketReady] = useState(false)

    const notify = (event: SocketEvent) => {
        socketListeners.current.forEach((listener) =>
            listener.handleSocketEvent(event)
        )
        if (event.event instanceof MessageEvent) {
            const messageEvent = event.event
            const json = JSON.parse(messageEvent.data)
            messageListeners.current.forEach((listener) =>
                listener.handleMessageEvent(messageEvent, json)
            )
        }
    }

    const sendAgentMessage = (message: AgentMessage) => {
        if (
            socket.current !== null &&
            socket.current.readyState === socket.current.OPEN
        ) {
            const messageStr: string = JSON.stringify(message)
            logger.info(`Sending ${messageStr}`)
            socket.current.send(messageStr)
            return true
        } else {
            return false
        }
    }

    const addMessageListeners = (...listeners: MessageEventListener[]) => {
        listeners.forEach((listener) => {
            if (messageListeners.current.indexOf(listener) == -1) {
                messageListeners.current.push(listener)
            }
        })
    }

    const removeMessageListener = (
        listener: MessageEventListener
    ): MessageEventListener | null => {
        const x = messageListeners.current.indexOf(listener)
        if (x > -1) {
            return messageListeners.current.splice(x, 1)[0]
        } else {
            return null
        }
    }

    useEffect(() => {
        const url = `ws://localhost:${port}`
        logger.info(`Connecting to ${url}`)
        socket.current = new WebSocket(url)
        socket.current.onopen = (e) => {
            logger.info(`Socket opened (${socket.current?.url})`)
            const event: SocketEvent = {
                type: "open",
                event: e,
            }
            setSocketReady(true)
            notify(event)
        }
        socket.current.onclose = (e) => {
            if (e.code === -1 && e.reason === "REPLACED") {
                logger.info("Socket closed (will be replaced)")
            } else {
                logger.info("Socket closed")
                const event: SocketEvent = {
                    type: "close",
                    event: e,
                }
                notify(event)
            }
            setSocketReady(false)
        }
        socket.current.onerror = (e) => {
            e.stopPropagation()
            logger.error("Socket error")
            const event: SocketEvent = {
                type: "error",
                event: e,
            }
            notify(event)
        }
        socket.current.onmessage = (e) => {
            logger.trivial("Agent_message_received")
            logger.trivial(e.data)
            const event: SocketEvent = {
                type: "message",
                event: e,
            }
            notify(event)
        }

        const wsCurrent = socket.current

        return () => {
            wsCurrent.close()
        }
    }, [])

    return (
        <AgentContext.Provider
            value={{
                sendAgentMessage,
                addMessageListeners,
                removeMessageListener,
                notify,
                socketReady,
            }}
        >
            {children}
        </AgentContext.Provider>
    )
}

export function useAgentContext(): AgentContextProps {
    const ctx = useContext(AgentContext)
    if (ctx === undefined) throw Error("Agent Context is undefined")
    return ctx
}

export default AgentContext
