import { ozoneMessages, setOzoneMessage } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { ComponentProps } from "react"
import { useDeviceContext } from "@/context/DeviceContext"
import { useAgentContext } from "@/context/AgentContext"

const ZoneNames = ["A", "B", "C", "W"]
export default function TwoChannelZoneSelector(
    props: ComponentProps<"select">
) {
    const { sendAgentMessage } = useAgentContext()
    const { settings, setSettings } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const zoneIndex = parseInt(event.target.value)
        if (settings.oZoneIndex !== `${zoneIndex}`) {
            sendAgentMessage(new setOzoneMessage(ozoneMessages[zoneIndex]))
            setSettings({ oZoneIndex: `${zoneIndex}` })
        }
    }

    const values = []
    for (const i in ZoneNames) {
        values.push(
            <option value={i} key={i}>
                Zone {ZoneNames[i]}
            </option>
        )
    }

    return (
        <SelectInput
            value={`${settings.oZoneIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {values}
        </SelectInput>
    )
}
