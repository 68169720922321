import { SoundCardSelectorProps } from "@/types/DeviceSelector"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"
import LoggerService from "@/services/LoggerService"
import { inputChannelCountPossibilities } from "@/types/AppMessage"

export default function InputSoundCardSelector(props: SoundCardSelectorProps) {
    const { settings, setSettings, inputSoundCards } = useDeviceContext()

    const logger = new LoggerService("InputSoundCardSelector")

    const handleChange = (name: string) => {
        const newSoundCard = inputSoundCards.find((sc) => sc.name === name)
        if (newSoundCard === undefined) {
            logger.error("Sound card doesn't exist in list")
            return
        }
        const channelsIndex = inputChannelCountPossibilities.reduce(
            (acc, curr, index) => {
                if (curr === newSoundCard.inputChannels) return index
                else return acc
            },
            0
        )
        setSettings({
            inputSoundCard: newSoundCard,
            inputChannelsIndex: `${channelsIndex}`,
        })
    }

    return (
        <SelectInput
            disabled={props.disabled}
            value={`${settings.inputSoundCard ? settings.inputSoundCard.name : "NONE"}`}
            onChange={(event) => handleChange(event.target.value)}
        >
            <option key={"None"} value={"NONE"}>
                None
            </option>
            {inputSoundCards.map((soundCard, i) => {
                return (
                    <option
                        key={i}
                        value={soundCard.name}
                        selected={
                            settings.inputSoundCard !== undefined &&
                            settings.inputSoundCard.name === soundCard.name
                        }
                    >
                        {soundCard.index} {soundCard.name} (
                        {soundCard.inputChannels} in, {soundCard.outputChannels}{" "}
                        out)
                    </option>
                )
            })}
        </SelectInput>
    )
}
