import { SoundCardSelectorProps } from "@/types/DeviceSelector"
import { useDeviceContext } from "@/context/DeviceContext"
import LoggerService from "@/services/LoggerService"
import SelectInput from "../formInputs/SelectInput"

export default function OutputSoundCardSelector(props: SoundCardSelectorProps) {
    const { settings, setSettings, outputSoundCards } = useDeviceContext()

    const logger = new LoggerService("OutputSoundCardSelector")

    const handleChange = (name: string) => {
        const newSoundCard = outputSoundCards.find((sc) => sc.name === name)
        if (newSoundCard === undefined) {
            logger.error("Sound card doesn't exist in list")
            return
        }
        const outputChannels = `${newSoundCard.outputChannels >= 2 ? 2 : 1}`
        setSettings({
            outputSoundCard: newSoundCard,
            outputChannels,
        })
    }

    return (
        <SelectInput
            disabled={props.disabled}
            value={`${settings.outputSoundCard ? settings.outputSoundCard.name : "NONE"}`}
            onChange={(event) => handleChange(event.target.value)}
        >
            <option key={"None"} value={"NONE"}>
                None
            </option>
            {outputSoundCards.map((soundCard, i) => {
                return (
                    <option
                        key={i}
                        value={soundCard.name}
                        selected={
                            settings.outputSoundCard !== undefined &&
                            settings.outputSoundCard.name === soundCard.name
                        }
                    >
                        {soundCard.index} {soundCard.name} (
                        {soundCard.outputChannels} in,{" "}
                        {soundCard.outputChannels} out)
                    </option>
                )
            })}
        </SelectInput>
    )
}
