import { diffuseIRDepthMessageV240124 } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"
import { useAgentContext } from "@/context/AgentContext"
import { ComponentProps } from "react"

export default function DiffuseIRDepthSelector(
    props: ComponentProps<"select">
) {
    const { sendAgentMessage } = useAgentContext()
    const { settings, setSettings } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const depthIndex = parseInt(event.target.value)
        if (settings.diffuseIRLevelIndex != `${depthIndex}`) {
            setSettings({ diffuseIRLevelIndex: `${depthIndex}` })
            sendAgentMessage(new diffuseIRDepthMessageV240124(depthIndex))
        }
    }

    return (
        <SelectInput
            value={`${settings.diffuseIRLevelIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {[...Array(5)].map((_, i) => (
                <option value={i} key={i}>
                    {i == 0 ? "Diffuse IR off" : `Diffuse IR depth ${i}`}
                </option>
            ))}
            <option value={5} key={5}>
                Mute locals
            </option>
        </SelectInput>
    )
}
