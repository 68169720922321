import { ComponentProps } from "react"
import SelectInput from "../formInputs/SelectInput"

export type GenericSelectorProps = ComponentProps<"select"> & {
    options: string[]
    value: string | undefined
    callback: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export default function GenericSelector(props: GenericSelectorProps) {
    return (
        <SelectInput
            title={props.title}
            value={props.value}
            disabled={props.disabled}
            onChange={props.callback}
        >
            {props.options.map((option, index) => (
                <option value={index} key={option}>
                    {option}
                </option>
            ))}
        </SelectInput>
    )
}
