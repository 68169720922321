import { SoundCard, VideoDevice } from "@/context/DeviceContext"

export type BaseSettings = {
    videoDevice: VideoDevice | undefined
    inputSoundCard: SoundCard | undefined
    outputSoundCard: SoundCard | undefined
    outputChannels: string
    inputChannelsIndex: string
    spatialLocation: string
    diffuseIRLevelIndex: string
    oZoneIndex: string
    directOnOff: string
    videoColorIndex: string
    videoResolutionIndex: string
    userName: string
    frameSizeIndex: string
    faderPos: string
}

export type Settings = BaseSettings & {
    // --- "advanced" settings ---
    sampleRateIndex: string
    networkBufferSizeIndex: string
    codecOptionsIndex: string
    manualJitterIndex: string
    networkInterface: string | undefined
}

export type SettingsAction = Partial<Settings>

export const settingsReducer = (state: Settings, action: SettingsAction) => {
    return { ...state, ...action }
}

export const getBaseSettings = (settings: Settings): BaseSettings => settings
