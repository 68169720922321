export type StageMode = "default" | "mixer" | "room" | "positions"

export type StageControlOptions = {
    stageMode: StageMode
    positions360: boolean
    videoEnable: boolean
    videoDisplayEnable: boolean
    micEnable: boolean
    recordEnable: boolean
    shouldLeave: boolean
}

export type StageControlAction = Partial<StageControlOptions>

export function stageReducer(
    state: StageControlOptions,
    action: StageControlAction
) {
    if (action.stageMode === state.stageMode) action.stageMode = "default"

    return { ...state, ...action }
}
