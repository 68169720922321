import { PlayerAction, playerReducer } from "@/reducers/playerReducer"
import LoggerService from "@/services/LoggerService"
import { getUserSessions } from "@/services/UserService"
import {
    RemoteInfoData,
    setReceiverBufferSizeMessage,
    setScreenSharingMessage,
    StartStreamMessage,
    stopStreamMessage,
} from "@/types/AppMessage"
import {
    BonzaSessionResponse,
    BonzaSessionUpdatedProps,
} from "@/types/BonzaSession"
import {
    ConnectionResource,
    ConnectionWithUserResource,
} from "@/types/Connection"
import { PlayerViewRemote } from "@/types/PlayerView"
import {
    AcceptedUserInvitationNotification,
    UserInvitationNotification,
} from "@/types/User"
import { User } from "@/types/UserClass"
import { MessageEventListener } from "@/types/WebSocket"
import {
    QueryObserverResult,
    RefetchOptions,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { enqueueSnackbar, SnackbarProvider } from "notistack"
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    Reducer,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react"
import axios from "axios"
import {
    getBonzaSessionPeers,
    postJoinBonzaSession,
    postLeaveBonzaSession,
    putConnect,
} from "@/services/ConnectionService"
import {
    StageControlAction,
    StageControlOptions,
    stageReducer,
} from "@/reducers/stageReducer"
import { useAgentContext } from "@/context/AgentContext"
import { router } from "@inertiajs/react"
import { getMirrors } from "@/services/MirrorService"
import { Mirror } from "@/types/Mirror"

type BonzaContextProps = {
    getConnection: () => ConnectionResource | undefined
    setConnection: Dispatch<
        React.SetStateAction<ConnectionResource | undefined>
    >
    updateConnection: (connection: ConnectionResource) => void
    user: User | undefined
    setUser: Dispatch<User | undefined>
    peers: ConnectionWithUserResource[] | undefined
    fetchPeers: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<ConnectionWithUserResource[], Error>>
    players: PlayerViewRemote[]
    setPlayers: Dispatch<PlayerAction>
    avatarAzimuth: number
    screenSharing: boolean
    toggleScreenSharing: () => void
    stageOptions: StageControlOptions
    setStageOptions: Dispatch<StageControlAction>
    connected: boolean
    skipSetup: boolean
    setSkipSetup: Dispatch<boolean>
    setupBackRoute: string
    joinBonzaSession: (
        sessionId: string
    ) => Promise<ConnectionResource> | undefined
    leaveBonzaSession: () => Promise<ConnectionResource> | undefined
    getPeer: (identifier: string) => ConnectionWithUserResource | undefined
    getPeerById: (id: number) => ConnectionWithUserResource | undefined
    isBonzaApp: boolean
    recording: boolean
    setRecording: Dispatch<boolean>
    mirrors: Mirror[] | undefined
}

const BonzaContext = createContext<BonzaContextProps | undefined>(undefined)

export const BonzaContextProvider = ({ children }: PropsWithChildren) => {
    const [user, setUser] = useState<User | undefined>(undefined)
    const { sendAgentMessage, addMessageListeners, socketReady } =
        useAgentContext()

    const logger = new LoggerService("BonzaContext")
    const [connection, setConnection] = useState<
        ConnectionResource | undefined
    >()
    const [isBonzaApp, setIsBonzaApp] = useState(false)

    const [players, setPlayers] = useReducer(
        playerReducer,
        new Array<PlayerViewRemote>()
    )

    const updateConnection = (updatedConnection: ConnectionResource) => {
        setConnection(updatedConnection)
        if (updatedConnection)
            putConnect({
                identifier: updatedConnection.identifier,
                engine_ip: updatedConnection.engine_ip,
                interface_ip: updatedConnection.interface_ip,
                udp_port: updatedConnection.udp_port,
                udp_port_2: `${updatedConnection.udp_port} (${updatedConnection.port_status})`,
                port_status: updatedConnection.port_status,
                metadata: updatedConnection.metadata,
            })
    }

    const getConnection = () => {
        return connection
    }

    const {
        data: peers,
        refetch: fetchPeers,
        status,
        fetchStatus,
    } = useQuery({
        queryKey: ["peers"],
        queryFn: async () => {
            if (connection === undefined) {
                logger.warn("Query: Connection is undefined")
                return []
            }
            logger.info(`Fetching session peers for id ${connection.id}`)
            const peers = await getBonzaSessionPeers(`${connection.id}`)

            logger.info(`Returning ${peers.length} peers`)
            return peers
        },
        enabled: !!connection,
    })

    const getPeer = (identifier: string) =>
        peers?.find((peer) => peer.identifier === identifier)

    const getPeerById = (id: number) => peers?.find((peer) => peer.id === id)

    useEffect(() => {
        if (status !== "success" || fetchStatus !== "idle") return

        // Remove players
        players
            .filter((player) => getPeerById(player.id) === undefined)
            .forEach((player) => {
                disconnect(player.id)
                setPlayers({
                    action: "DELETE",
                    partialPlayer: {
                        id: player.id,
                    },
                })
            })

        // Add new players
        peers
            ?.filter(
                (peer) =>
                    players.findIndex((player) => player.id === peer.id) === -1
            )
            .forEach((peer) => {
                connect(peer)
                setPlayers({
                    action: "CREATE",
                    player: {
                        id: peer.id,
                        name: peer.user.name,
                        identifier: peer.identifier,
                        ip: `${peer.engine_ip}`,
                        port: `${peer.udp_port}`,
                        readiness: "verified",
                        pan: 0,
                        volume: 100,
                        jitter: "auto",
                        suggestedJitter: "5",
                    },
                })
            })
    }, [peers, status, fetchStatus])

    const joinBonzaSession = (
        sessionId: string
    ): Promise<ConnectionResource> => {
        // Check audio status here!!
        // if (deviceReady !== "true") {
        //     enqueueSnackbar(
        //         "Audio is not ready, please check Settings and ensure your input and output devices are set up",
        //         { variant: "error" }
        //     )
        //     return Promise.reject()
        // }

        const connectionId = getConnection()?.id.toString()
        if (!sessionId || !connectionId) {
            enqueueSnackbar(
                "An unexpected error occured, please try again in a few seconds",
                { variant: "error" }
            )
            return Promise.reject()
        }

        const promise = postJoinBonzaSession(connectionId, sessionId)
        promise.then(() => fetchPeers())

        return promise
    }

    const leaveBonzaSession = () => {
        const connection = getConnection()
        if (connection && connection.bonza_session_id) {
            const promise = postLeaveBonzaSession(`${connection.id}`)

            promise.then(() => {
                fetchPeers()
            })

            return promise
        }
    }

    const [avatarAzimuth, setAvatarAzimuth] = useState(0)

    const [screenSharing, setScreenSharing] = useState(false)

    const toggleScreenSharing = () => {
        if (!screenSharing) {
            sendAgentMessage(new setScreenSharingMessage(true))
            setScreenSharing(true)
        } else {
            sendAgentMessage(new setScreenSharingMessage(false))
            setScreenSharing(false)
        }
    }

    const [stageOptions, setStageOptions] = useReducer<
        Reducer<StageControlOptions, StageControlAction>
    >(stageReducer, {
        stageMode: "default",
        positions360: false,
        videoEnable: false,
        videoDisplayEnable: false,
        micEnable: true,
        recordEnable: false,
        shouldLeave: false,
    })

    const agentMessageListener: MessageEventListener = {
        handleMessageEvent(_: MessageEvent, message: any | null | undefined) {
            if (!message || !message.type) return

            switch (message.type) {
                case "tellPort": {
                    setConnection((connection) => {
                        if (
                            connection &&
                            (connection.udp_port !== message.localPort ||
                                //connection.tcp_port !== message.localBindPort ||
                                connection.udp_port_2 !== message.localPort2 ||
                                connection.engine_ip !== message.localIP ||
                                connection.interface_ip !==
                                    message.interfaceIP ||
                                connection.port_status !== message.NAT)
                        ) {
                            const metadata: any = connection.metadata ?? {}
                            const newConnection: ConnectionResource = {
                                ...connection,
                                udp_port: message.localPort,
                                //tcp_port: message.localBindPort,
                                engine_ip: message.localIP,
                                interface_ip: message.interfaceIP,
                                port_status: message.NAT,
                                metadata: {
                                    ...metadata,
                                    os: message.OS,
                                    internalPort: 50050,
                                },
                            }
                            putConnect(newConnection)
                            return newConnection
                        }
                        return connection
                    })
                    break
                }
                case "tellLatency":
                    {
                        // sendAgentMessageing curr latency for display
                        const idStr = message.data1
                        if (idStr)
                            setPlayers({
                                action: "EDIT",
                                partialPlayer: {
                                    id: +idStr,
                                    latency: message.data2,
                                    dropout: ".",
                                },
                            })
                    }
                    break
                case "tellDropout":
                    {
                        // sendAgentMessageing curr dropout for display
                        const idStr = message.data1
                        const logDropout: boolean = false
                        if (logDropout) {
                            logger.info(
                                `Stage:tellDropout ${idStr} ${message.data2}`
                            )
                        }
                        if (idStr)
                            setPlayers({
                                action: "EDIT",
                                partialPlayer: {
                                    id: +idStr,
                                    dropout: message.data2,
                                },
                            })
                    }
                    break
                case "setJitterBuffer": {
                    // sendAgentMessageing curr jitter suggestion - we dont have to do it if already in 'manual' mode
                    const idStr: string = message.ID
                    setPlayers({
                        action: "EDIT",
                        partialPlayer: {
                            id: +idStr,
                            suggestedJitter: message.newJBValue,
                        },
                        editCallback: (player) => {
                            if (player.jitter === "auto")
                                sendAgentMessage(
                                    new setReceiverBufferSizeMessage(
                                        idStr,
                                        player.suggestedJitter
                                    )
                                )
                        },
                    })
                    break
                }
                case "headTracker": {
                    const azimuth = message.azimuth
                    if (azimuth === undefined || typeof azimuth !== "number")
                        logger.log("Invalid azimuth message")
                    else setAvatarAzimuth(azimuth)
                    break
                }
                case "sendRemoteVideoImage":
                case "sendVideoImage":
                case "sendRemoteSoundLevel":
                    break
                default: {
                    logger.log("No valid message")
                    break
                }
            }
        },
    }

    const queryClient = useQueryClient()

    const { data: dataSessions } = useQuery<BonzaSessionResponse[]>({
        queryKey: ["sessions"],
        queryFn: getUserSessions,
        enabled: user !== undefined,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
    })

    const [currentDataSessions, setCurrentSessions] = useState<
        BonzaSessionResponse[]
    >([])

    const enteredSession = (remote: ConnectionWithUserResource) => {
        enqueueSnackbar(`User joined: ${remote.user.name}`)

        fetchPeers()
    }

    const leftSession = (remoteConnection: ConnectionWithUserResource) => {
        enqueueSnackbar(`User left: ${remoteConnection.user.name}`)

        fetchPeers()
    }

    const connect = (remote: ConnectionWithUserResource) => {
        const connection = getConnection()
        if (!(connection?.engine_ip && connection.udp_port)) {
            logger.error("Own connection is not set up")
            enqueueSnackbar(
                "Your connection is not ready, please wait a few seconds then try again.",
                { variant: "warning" }
            )
            return false
        }
        if (!(remote.engine_ip && remote.udp_port)) {
            logger.error(
                `Remote ID ${remote.id} (${remote.user.name}) connection is not set up`
            )
            return false
        }
        const isOnLocalNetwork = remote.engine_ip === connection.engine_ip
        const params: RemoteInfoData = {
            IP: isOnLocalNetwork
                ? `${remote.interface_ip}`
                : `${remote.engine_ip}`,
            port: isOnLocalNetwork ? "50050" : `${remote.udp_port!}`,
            ownID: connection.id,
            remoteSenderID: remote.id,
            currentGroupPosition: 0,
            remoteNAT: `${connection.udp_port} (${connection.port_status})`,
            remotePortTCP: `${connection.tcp_port}`,
            remoteSocketIP: `${connection.engine_ip}`,
        }

        sendAgentMessage(new StartStreamMessage(params))

        enqueueSnackbar(`You're connected to ${remote.user.name}`, {
            variant: "success",
        })

        return true
    }

    const disconnect = (id: number, name?: string) => {
        const disconnectMsg = new stopStreamMessage(`${id}`)

        sendAgentMessage(disconnectMsg)

        enqueueSnackbar(
            `You've disconnected from ${name ?? `Connection ID #${id}`}`,
            {
                variant: "error",
            }
        )
    }

    const sessionUpdateCallback = async (data: BonzaSessionUpdatedProps) => {
        if (data.activated_at) {
            enqueueSnackbar(`Session started: ${new Date(data.activated_at)}`)
        } else if (data.deactivated_at) {
            enqueueSnackbar(`Session ended: ${new Date(data.deactivated_at)}`)
            fetchPeers()
        } else if (data.joined) {
            enteredSession(data.joined)
        } else if (data.left) {
            leftSession(data.left)
        } else if (data.user) {
            queryClient.invalidateQueries({
                queryKey: ["sessionMembers", data.id!.toString()],
            })
        } else {
            enqueueSnackbar(`Session updated: ${data.name}`)
        }
    }

    useEffect(() => {
        if (dataSessions !== undefined) {
            dataSessions
                ?.filter(
                    (session) =>
                        currentDataSessions.findIndex(
                            (currentSession) => session.id === currentSession.id
                        ) === -1
                )
                .forEach((session) => {
                    window.Echo.private(`Bonza.Session.${session.id}`)
                        .error((error: unknown) => console.error(error))
                        .subscribed(() =>
                            console.log(`Subscribed to ${session.id}`)
                        )
                        .listen("BonzaSessionUpdated", sessionUpdateCallback)
                })
            setCurrentSessions(dataSessions)
        }
    }, [dataSessions])

    useEffect(() => {
        const heartbeat = setInterval(async () => {
            axios.get(route("api.connection.heartbeat")).catch((error) => {
                logger.warn(`Connection heartbeat failed: ${error}`)
            })
        }, 10000)

        // Forces connection to load, updating settings
        router.reload({ only: ["connection"] })

        addMessageListeners(agentMessageListener)

        if (user)
            window.Echo.private(`App.Models.User.${user.id}`)
                .error((error: unknown) => console.error(error))
                .subscribed(() => console.log(`App.Models.User.${user.id}`))
                .listen(
                    "UserInvitedToBonzaSession",
                    async ({
                        bonza_session,
                        invited_by,
                    }: UserInvitationNotification) => {
                        enqueueSnackbar(
                            `You have been invited to ${bonza_session.name} by ${invited_by.name}.`
                        )
                        await queryClient.invalidateQueries({
                            queryKey: ["invites"],
                        })
                    }
                )
                .listen(
                    "UserAcceptedInviteToBonzaSession",
                    async ({
                        bonza_session,
                        user,
                    }: AcceptedUserInvitationNotification) => {
                        enqueueSnackbar(
                            `${user.name} has accepted your invitation to ${bonza_session.name}.`
                        )
                        await queryClient.invalidateQueries({
                            queryKey: ["sessionMembers", bonza_session.id],
                        })
                    }
                )

        return () => {
            clearTimeout(heartbeat)
            window.Echo.disconnect()
        }
    }, [])

    const [skipSetup, setSkipSetup] = useState(false)
    const [setupBackRoute] = useState(route("dashboard"))

    useEffect(() => {
        setIsBonzaApp(socketReady)
    }, [socketReady])

    const [recording, setRecording] = useState(false)

    const { data: mirrors } = useQuery({
        queryKey: ["mirrors"],
        queryFn: getMirrors,
    })

    return (
        <BonzaContext.Provider
            value={{
                getConnection,
                setConnection,
                updateConnection,
                players,
                setPlayers,
                peers,
                fetchPeers,
                user,
                setUser,
                avatarAzimuth,
                screenSharing,
                toggleScreenSharing,
                setStageOptions,
                stageOptions,
                connected: socketReady,
                skipSetup,
                setSkipSetup,
                setupBackRoute,
                joinBonzaSession,
                leaveBonzaSession,
                getPeer,
                getPeerById,
                isBonzaApp,
                recording,
                setRecording,
                mirrors,
            }}
        >
            <SnackbarProvider maxSnack={5} disableWindowBlurListener={true}>
                {children}
            </SnackbarProvider>
        </BonzaContext.Provider>
    )
}

export function useBonzaContext(): BonzaContextProps {
    const ctx = useContext(BonzaContext)
    if (ctx === undefined) throw Error("Bonza Context is undefined")
    return ctx
}

export default BonzaContext
