import { setColorOrBWMessage } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"
import { useAgentContext } from "@/context/AgentContext"
import { ComponentProps } from "react"

const ColorOpts = ["BW", "Colour"]
export const defaultBwIndex: number = 1
export default function VideoColorSelector(props: ComponentProps<"select">) {
    const { sendAgentMessage } = useAgentContext()
    const { settings, setSettings } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const bwIndex = parseInt(event.target.value)
        if (settings.videoColorIndex != `${bwIndex}`) {
            setSettings({ videoColorIndex: `${bwIndex}` })
            sendAgentMessage(new setColorOrBWMessage(bwIndex))
        }
    }

    return (
        <SelectInput
            value={settings.videoColorIndex}
            disabled={props.disabled}
            onChange={handleChange}
        >
            {ColorOpts.map((opt, index) => (
                <option
                    value={index}
                    key={opt}
                    selected={opt === ColorOpts[+settings.videoColorIndex]}
                >
                    {opt}
                </option>
            ))}
        </SelectInput>
    )
}
