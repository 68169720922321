import PrimaryButton from "@/components/Button"
import { useMemo, useState } from "react"
import { SoundCardIOType } from "@/types/DeviceSelector"
import VideoDeviceSelector from "@/components/settingsSelectors/VideoDeviceSelector"
import {
    Construction as SettingsIcon,
    ExitToApp as InputIcon,
    Output as OutputIcon,
} from "@mui/icons-material"
import { LR_Type } from "@/components/AudioControlLocal"
import VuMeterMini from "@/components/VuMeterMini"
import InputSoundCardSelector from "@/components/settingsSelectors/InputSoundCardSelector"
import OutputSoundCardSelector from "@/components/settingsSelectors/OutputSoundCardSelector"
import InputChannelsSelector from "@/components/settingsSelectors/InputChannelsSelector"
import OutputChannelsSelector from "@/components/settingsSelectors/OutputChannelsSelector"
import LatencySelector from "@/components/settingsSelectors/LatencySelector"
import {
    CodecOptionStrings,
    NetworkBufferSizeStrings,
    openASIOPanelMessage,
    playTestSoundMessage,
    playTestSoundMode,
    SampleRateValueStrings,
    setStreamQualityMessage,
} from "@/types/AppMessage"

import DiffuseIRDepthSelector from "@/components/settingsSelectors/DiffuseIRDepthSelector"
import TwoChannelZoneSelector from "@/components/settingsSelectors/TwoChannelZoneSelector"
import DirectAudioCheckbox from "@/components/checkboxes/DirectAudioCheckbox"
import VideoColorSelector from "../settingsSelectors/VideoColourSelector"
import VideoResolutionSelector from "../settingsSelectors/VideoResolutionSelector"
import VideoDisplay from "@/components/VideoDisplay"
import GenericSelector from "@/components/settingsSelectors/GenericSelector"
import { useBonzaContext } from "@/context/BonzaContext"
import Checkbox from "../Checkbox"
import Dialog from "../Dialog"
import { NavBarButton } from "../NavButton"
import Plus from "../icons/plus"
import Video from "../icons/video"
import { useDeviceContext } from "@/context/DeviceContext"
import { useAgentContext } from "@/context/AgentContext"
import LoggerService from "@/services/LoggerService"

type SettingsDialogProps = {
    show: boolean
    closeCallback: () => void
}

type SettingsMode = "input" | "output" | "advanced" | "video"

export default function SettingsDialog(props: SettingsDialogProps) {
    const [mode, setMode] = useState<SettingsMode>("input")

    return (
        <Dialog
            show={props.show}
            aria-labelledby={"local-device-dialog-title"}
            closeable
            onClose={props.closeCallback}
        >
            <div
                className={`
                    flex max-h-fit flex-row items-stretch transition-all
                    duration-100 ease-in-out
                `}
            >
                <div
                    className={`
                        flex w-[215px] flex-col gap-6 rounded-l-3xl
                        rounded-r-none bg-bonza-dark p-6
                    `}
                >
                    <NavBarButton
                        label="Input"
                        active={mode == "input"}
                        onClick={() => setMode("input")}
                    >
                        <InputIcon className="rotate-180" />
                    </NavBarButton>
                    <NavBarButton
                        label="Output"
                        active={mode == "output"}
                        onClick={() => setMode("output")}
                    >
                        <OutputIcon />
                    </NavBarButton>
                    <NavBarButton
                        label="Video"
                        active={mode == "video"}
                        onClick={() => setMode("video")}
                    >
                        <Video />
                    </NavBarButton>
                    <NavBarButton
                        label="Advanced"
                        active={mode == "advanced"}
                        onClick={() => setMode("advanced")}
                    >
                        <SettingsIcon />
                    </NavBarButton>
                </div>

                <div
                    className={`
                        flex flex-grow flex-col items-stretch gap-2
                        overflow-y-auto rounded-l-none rounded-r-3xl
                        bg-bonza-dark-semi p-6
                    `}
                >
                    <div
                        className={`flex flex-row items-center justify-between`}
                        id="local-device-dialog-title"
                    >
                        <h1 className={`text-lg capitalize text-bonza-pale`}>
                            {mode}
                        </h1>
                        <button
                            onClick={() => props.closeCallback()}
                            className={`
                                relative rounded-full bg-bonza-dark-semi p-1
                                text-bonza-pale ring-0 transition duration-100
                                ease-in-out

                                hover:ring hover:ring-bonza-primary
                            `}
                        >
                            <Plus className="h-4 w-4 rotate-45" />
                        </button>
                    </div>
                    <div className={`min-w-[500px] text-bonza-pale`}>
                        {
                            {
                                input: <InputMode />,
                                output: <OutputMode />,
                                advanced: <AdvancedMode />,
                                video: <VideoMode />,
                            }[mode]
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

function InputMode() {
    const { devices, settings } = useDeviceContext()
    const allowSelect = useMemo(() => devices.soundCards.length > 0, [devices])

    return (
        <>
            <div
                className={`
                    grid w-full grid-cols-[30%_70%] items-center gap-y-4 pt-4
                `}
            >
                <label
                    className={`
                        text-md

                        ${!allowSelect ? `text-bonza-grey` : ""}
                    `}
                >
                    Input device
                </label>
                <InputSoundCardSelector
                    disabled={!allowSelect}
                    type={SoundCardIOType.Input}
                />

                <label
                    className={`
                        text-md

                        ${
                            settings.inputSoundCard === undefined
                                ? `text-bonza-grey`
                                : ""
                        }
                    `}
                >
                    Input channels
                </label>
                <InputChannelsSelector
                    disabled={settings.inputSoundCard === undefined}
                />

                <label
                    className={`
                        text-md

                        ${
                            settings.inputSoundCard === undefined
                                ? `text-bonza-grey`
                                : ""
                        }
                    `}
                >
                    Latency
                </label>
                <LatencySelector
                    disabled={settings.inputSoundCard === undefined}
                />
            </div>
            <hr className="my-8 border-bonza-grey" />
            <label
                className={`
                    text-md

                    ${
                        settings.inputSoundCard === undefined
                            ? `text-bonza-grey`
                            : ""
                    }
                `}
            >
                Test your input
            </label>
            <VuMeterMini
                inactive={false}
                invert={false}
                lrtype={LR_Type.Local}
                columns={2}
                channel={0}
                className={`-ml-[2px] h-[30px] w-[435px]`}
            />
        </>
    )
}

function OutputMode() {
    const { sendAgentMessage } = useAgentContext()
    const { devices, settings } = useDeviceContext()
    const allowSelect = useMemo(() => devices.soundCards.length > 0, [devices])

    return (
        <>
            <div
                className={`
                    grid w-full grid-cols-[30%_70%] items-center gap-y-4 pt-4
                `}
            >
                <label
                    className={`
                        text-md

                        ${!allowSelect ? `text-bonza-grey` : ""}
                    `}
                >
                    Output device
                </label>

                <OutputSoundCardSelector
                    disabled={!allowSelect}
                    title="Output Card"
                    type={SoundCardIOType.Output}
                />
                <label
                    className={`
                        text-md

                        ${
                            settings.outputSoundCard === undefined
                                ? `text-bonza-grey`
                                : ""
                        }
                    `}
                >
                    Output channels
                </label>
                <OutputChannelsSelector
                    disabled={settings.outputSoundCard === undefined}
                    name="OutputChannelsSelector"
                />
            </div>
            <hr className="my-8 border-bonza-grey" />
            <label
                className={`
                    text-md

                    ${
                        settings.outputSoundCard === undefined
                            ? `text-bonza-grey`
                            : ""
                    }
                `}
            >
                Test your output
            </label>
            <VuMeterMini
                inactive={true}
                invert={false}
                lrtype={LR_Type.Local}
                columns={2}
                className={`-ml-[2px] mb-3 h-[30px] w-[435px]`}
            />
            <PrimaryButton
                disabled={settings.outputSoundCard === undefined}
                onClick={() =>
                    sendAgentMessage(
                        new playTestSoundMessage(
                            1.5,
                            playTestSoundMode.left_then_right
                        )
                    )
                }
            >
                Play sound
            </PrimaryButton>
        </>
    )
}

function VideoMode() {
    const { devices, settings } = useDeviceContext()
    const { screenSharing, toggleScreenSharing } = useBonzaContext()
    const allowSelect = useMemo(
        () => devices.videoDevices.length > 0,
        [devices]
    )

    return (
        <>
            <div
                className={`
                    grid w-full grid-cols-[30%_70%] items-center gap-y-4 pt-4
                `}
            >
                <label
                    className={`
                        text-md

                        ${!allowSelect ? `text-bonza-grey` : ""}
                    `}
                >
                    Camera
                </label>
                <VideoDeviceSelector
                    disabled={!allowSelect}
                ></VideoDeviceSelector>
                {/* Rez */}
                <label className={`text-md`}>Resolution</label>
                <VideoResolutionSelector />
                {/* BW */}
                <label className={`text-md`}>Colour Mode</label>
                <VideoColorSelector />
                {/* Screen sharing */}
                <label className={`text-md`}>Screen Sharing</label>
                <Checkbox
                    checked={screenSharing}
                    onChange={toggleScreenSharing}
                />
            </div>
            <hr className="my-8 border-bonza-grey" />
            <VideoDisplay
                className={`
                    aspect-video w-full overflow-hidden rounded-3xl border
                    border-bonza-dark bg-bonza-dark bg-cover bg-center p-2
                `}
                style={settings.videoDevice ? {} : { opacity: 0.5 }}
            />
        </>
    )
}

function AdvancedMode() {
    const { sendAgentMessage } = useAgentContext()
    const { devices, settings, setSettings, developerMode, setDeveloperMode } =
        useDeviceContext()
    const logger = new LoggerService("Settings Dialog")
    const [showAdvanced, setShowAdvanced] = useState(false)

    function asioClickHandler() {
        //const os: string | null = LocalRemoteManager.workingData.selfInfo.OS
        const os = "MAC OSX"
        if (os == null || os === "MAC OSX") {
            return // Mac doesnt use asio
        }
        const inputCard = settings.inputSoundCard
        const outputCard = settings.outputSoundCard
        if (inputCard && outputCard) {
            const indexin: number = inputCard.index
            const indexout: number = outputCard.index
            sendAgentMessage(new openASIOPanelMessage(indexin, indexout))
        }
    }

    return (
        <div className="flex flex-col">
            <div
                className={`
                    grid w-full grid-cols-[30%_70%] items-center gap-y-4 pt-4
                `}
            >
                <label className={`text-md`}>Show advanced settings</label>
                <Checkbox
                    checked={showAdvanced}
                    onChange={() => setShowAdvanced(!showAdvanced)}
                />
            </div>
            {showAdvanced && (
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] items-center gap-y-4
                        pt-4
                    `}
                >
                    <hr className="col-span-full my-8 border-bonza-grey" />
                    <label className={`text-md`}>Diffuse IR depth</label>
                    <DiffuseIRDepthSelector />
                    <label className={`text-md`}>Two-channel zone</label>
                    <TwoChannelZoneSelector />
                    <label className={`text-md`}>Direct on/off</label>
                    <DirectAudioCheckbox />
                    <hr className="col-span-full my-8 border-bonza-grey" />
                    {/* Old advanced options */}
                    <label className={`text-md`}>Device Sample Rate</label>
                    <GenericSelector
                        title="Device Sample Rate"
                        options={SampleRateValueStrings}
                        value={settings.sampleRateIndex.toString()}
                        callback={(e) => {
                            console.log(
                                `Selected sample rate index: ${e.target.value}`
                            )
                            setSettings({
                                sampleRateIndex: e.target.value.toString(),
                            })
                        }}
                    />
                    <label className={`text-md`}>Network buffer</label>
                    <GenericSelector
                        title="Network buffer"
                        options={NetworkBufferSizeStrings}
                        value={settings.networkBufferSizeIndex}
                        callback={(e) => {
                            console.log(
                                `Selected network buffer index: ${e.target.value}`
                            )
                            setSettings({
                                networkBufferSizeIndex:
                                    e.target.value.toString(),
                            })
                        }}
                    />
                    <label className={`text-md`}>Network Interface</label>
                    <GenericSelector
                        title="Network Interface"
                        options={devices.networkInterfaces}
                        value={settings.networkInterface}
                        callback={(e) => {
                            if (
                                devices.networkInterfaces[+e.target.value] !==
                                    undefined &&
                                settings.networkInterface !==
                                    devices.networkInterfaces[+e.target.value]
                            ) {
                                setSettings({
                                    networkInterface:
                                        devices.networkInterfaces[
                                            +e.target.value
                                        ],
                                })
                                logger.warn(
                                    `New Selected NIC index: ${e.target.value} - must reconnect engine`
                                )
                            }
                        }}
                    />
                    <label className={`text-md`}>Codec</label>
                    {/* this uses an object not an array as an example of how to do it in a slightly different way */}
                    <GenericSelector
                        options={CodecOptionStrings}
                        value={settings.codecOptionsIndex.toString()}
                        callback={(e) => {
                            setSettings({
                                codecOptionsIndex: Number(
                                    e.target.value
                                ).toString(),
                            })
                            sendAgentMessage(
                                new setStreamQualityMessage(e.target.value)
                            )
                        }}
                    />
                    <hr className="col-span-full my-8 border-bonza-grey" />
                    <label
                        className={`
                            text-md

                            ${
                                settings.outputSoundCard === undefined
                                    ? `text-bonza-grey`
                                    : ""
                            }
                        `}
                    >
                        Developer Mode
                    </label>
                    <Checkbox
                        checked={developerMode}
                        onChange={() => setDeveloperMode(!developerMode)}
                    />
                </div>
            )}
            {navigator.userAgent.toLowerCase().indexOf("windows") > -1 ? (
                <>
                    <hr className="my-8 border-bonza-grey" />
                    <PrimaryButton disabled={false} onClick={asioClickHandler}>
                        ASIO
                    </PrimaryButton>
                </>
            ) : null}
        </div>
    )
}
