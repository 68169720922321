import { ComponentProps } from "react"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext, VideoDevice } from "@/context/DeviceContext"

export default function VideoDeviceSelector(props: ComponentProps<"select">) {
    const { devices, settings, setSettings } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const videoDevice = devices.videoDevices.find(
            (c) => event.target.value === `${c.index}`
        ) ?? { index: 0, name: "NONE" }
        setSettings({ videoDevice })
    }

    return (
        <SelectInput
            value={`${settings.videoDevice ? settings.videoDevice.index : -1}`}
            disabled={props.disabled}
            onChange={(event) => handleChange(event)}
        >
            <option key={"None"} value={0}>
                None
            </option>
            {devices.videoDevices.map((videoDevice: VideoDevice, i) => {
                return (
                    <option key={i} value={videoDevice.index}>
                        {videoDevice.index} {videoDevice.name}
                    </option>
                )
            })}
        </SelectInput>
    )
}
