import { useMemo, useState } from "react"
import { Link } from "@inertiajs/react"
import SettingsDialog from "./dialogs/SettingsDialog"
import Home from "./icons/home"
import Plus from "./icons/plus"
import Groups from "./icons/groups"
import Live from "./icons/live"
import Settings from "./icons/settings"
import { NavBarButton, NavBarButtonProps } from "./NavButton"

type ActiveNav = "home" | "group" | "stage"

export default function NavBar() {
    const expanded = useMemo(
        () => route().current() !== "stage",
        [route().current()]
    )

    const active = useMemo<ActiveNav | undefined>(() => {
        const current = route().current()
        if (
            current === undefined ||
            current.includes("dashboard") ||
            current.includes("session")
        )
            return "home"
        if (current.includes("group")) return "group"
        if (current.includes("stage")) return "stage"
    }, [route().current()])

    const [showSettings, setShowSettings] = useState(false)

    return (
        <nav
            className={`
                flex flex-col items-start justify-start gap-10 p-10 pt-20
            `}
        >
            <ul className="flex flex-col items-start justify-start gap-10">
                <NavBarLink
                    expanded={expanded}
                    label="Home"
                    href={route("dashboard")}
                    active={active === "home"}
                >
                    <Home />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="Create new session"
                    href={route("session.create")}
                >
                    <Plus />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="My groups"
                    href={route("group")}
                    active={active === "group"}
                >
                    <Groups />
                </NavBarLink>
                <NavBarLink
                    expanded={expanded}
                    label="Active live room"
                    href={route("stage")}
                    active={active === "stage"}
                >
                    <Live />
                </NavBarLink>
                <div className="w-full border-t-2 border-bonza-dark-semi" />
                <NavBarButton
                    expanded={expanded}
                    label="Settings"
                    onClick={() => setShowSettings(true)}
                    active={showSettings}
                >
                    <Settings />
                </NavBarButton>
            </ul>
            <SettingsDialog
                show={showSettings}
                closeCallback={() => setShowSettings(false)}
            />
        </nav>
    )
}

type NavBarLinkProps = NavBarButtonProps & {
    href: string
}

const NavBarLink = ({ href, ...rest }: NavBarLinkProps) => {
    return (
        <Link href={href}>
            <NavBarButton {...rest} />
        </Link>
    )
}
