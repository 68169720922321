import { ComponentProps } from "react"
import { latencyStrings } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"

export default function LatencySelector(props: ComponentProps<"select">) {
    const { settings, setSettings, resetDeviceAudio } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index = event.currentTarget.value

        if (settings.frameSizeIndex !== `${index}`) {
            setSettings({ frameSizeIndex: `${index}` })
            resetDeviceAudio()
        }
    }

    return (
        <SelectInput
            value={`${settings.frameSizeIndex ?? "NONE"}`}
            onChange={handleChange}
            disabled={props.disabled}
        >
            <option key={"None"} value={"NONE"}>
                None
            </option>
            {latencyStrings.map((count, index) => (
                <option key={index} value={index}>
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
