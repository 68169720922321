import { ConnectionResource } from "@/types/Connection"
import { deleteRequest, getRequest, postRequest } from "./helpers/AxiosHelper"
import { Mirror } from "@/types/Mirror"

export const getMirrors = () =>
    getRequest<Mirror[]>(route("api.mirror.index"), "Mirror")

export const postMirror = (mirror: string) =>
    postRequest<undefined, ConnectionResource>(
        route("api.mirror.connect", { mirror }),
        undefined,
        "Mirror"
    )

export const postAndJoinMirror = (
    mirror: string,
    session: string,
    persistent?: boolean
) =>
    postRequest<undefined, ConnectionResource>(
        route("api.mirror.connect_and_join", {
            mirror,
            session,
            persistent: persistent ?? false,
        }),
        undefined,
        "Mirror"
    )

export const deleteMirror = (mirror: string) =>
    deleteRequest<ConnectionResource>(
        route("api.mirror.disconnect", { mirror }),
        "Mirror"
    )

export const joinMirror = (connection: string, session: string) =>
    getRequest<ConnectionResource>(
        route("api.mirror.join", { connection, session }),
        "Mirror"
    )

export const leaveMirror = (connection: string) =>
    getRequest<ConnectionResource>(
        route("api.mirror.leave", { connection }),
        "Mirror"
    )
