import { ComponentProps, useMemo } from "react"
import { outputChannelCountPossibilities } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"

export default function OutputChannelsSelector(
    props: ComponentProps<"select">
) {
    const { settings, setSettings } = useDeviceContext()

    const disabledOptions = useMemo(() => {
        return outputChannelCountPossibilities.map<boolean>((count) =>
            settings.outputSoundCard
                ? count > settings.outputSoundCard.outputChannels
                : false
        )
    }, [settings.outputSoundCard])

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings({ outputChannels: e.currentTarget.value })
        // restart audio engine
    }

    return (
        <SelectInput
            value={settings.outputChannels}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {outputChannelCountPossibilities.map((count, index) => (
                <option
                    key={index}
                    value={count}
                    disabled={disabledOptions[index]}
                >
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
