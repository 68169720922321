import { Select } from "@headlessui/react"
import { UseFormRegisterReturn } from "react-hook-form"

type SelectInputProps = React.ComponentPropsWithoutRef<"select"> & {
    register?: UseFormRegisterReturn<string>
}

export default function SelectInput({
    register,
    className,
    ...props
}: SelectInputProps) {
    return (
        <Select
            {...props}
            className={`
                rounded-3xl border-none bg-bonza-dark p-4 text-bonza-pale

                disabled:text-bonza-pale-t-7

                focus:border-bonza-primary focus:ring-bonza-primary

                ${className}
            `}
            {...register}
        />
    )
}
