import { ComponentProps, useMemo } from "react"
import { inputChannelCountPossibilities } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useDeviceContext } from "@/context/DeviceContext"

export default function InputChannelsSelector(props: ComponentProps<"select">) {
    const { settings, setSettings } = useDeviceContext()

    const disabledOptions = useMemo(() => {
        return inputChannelCountPossibilities.map<boolean>((count) =>
            settings.inputSoundCard
                ? count > settings.inputSoundCard.inputChannels
                : false
        )
    }, [settings.inputSoundCard])

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings({ inputChannelsIndex: e.currentTarget.value })
    }

    return (
        <SelectInput
            value={settings.inputChannelsIndex}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {inputChannelCountPossibilities.map((count, index) => (
                <option
                    key={index}
                    value={index}
                    disabled={disabledOptions[index]}
                >
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
