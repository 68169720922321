import { ComponentProps } from "react"

export enum SoundCardIOType {
    Input,
    Output,
}

export interface SoundCardSelectorProps extends ComponentProps<"select"> {
    title?: string
    type?: SoundCardIOType
    className?: string
}
