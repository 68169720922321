import { setVideoResolutionMessage } from "@/types/AppMessage"
import SelectInput from "../formInputs/SelectInput"
import { useAgentContext } from "@/context/AgentContext"
import { useDeviceContext } from "@/context/DeviceContext"
import { ComponentProps } from "react"

const ResNames = ["Max", "Med", "Small", "VSmall"]
export const defaultResIndex: number = 2
export default function VideoResolutionSelector(
    props: ComponentProps<"select">
) {
    const { sendAgentMessage } = useAgentContext()
    const { settings, setSettings } = useDeviceContext()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const resIndex = parseInt(event.target.value)
        if (settings.videoResolutionIndex != `${resIndex}`) {
            setSettings({ videoResolutionIndex: `${resIndex}` })
            sendAgentMessage(new setVideoResolutionMessage(resIndex))
        }
    }

    return (
        <SelectInput
            value={settings.videoResolutionIndex}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {ResNames.map((opt, index) => (
                <option
                    value={index}
                    key={opt}
                    selected={
                        opt === ResNames[Number(settings.videoResolutionIndex)]
                    }
                >
                    {opt}
                </option>
            ))}
        </SelectInput>
    )
}
