import { SoundCard, VideoDevice } from "@/context/DeviceContext"

export type Devices = {
    videoDevices: VideoDevice[]
    soundCards: SoundCard[]
    networkInterfaces: string[]
}

export type DevicesAction = {
    videoDevice?: VideoDevice
    soundCard?: SoundCard
    networkInterfaces?: string[]
}

export const deviceReducer = (state: Devices, action: DevicesAction) => {
    if (action.videoDevice)
        return {
            ...state,
            videoDevices: [...state.videoDevices, action.videoDevice],
        }
    if (action.soundCard)
        return { ...state, soundCards: [...state.soundCards, action.soundCard] }
    if (action.networkInterfaces)
        return {
            ...state,
            networkInterfaces: [
                ...state.networkInterfaces,
                ...action.networkInterfaces,
            ],
        }
    else return { ...state }
}
