import { Transition } from "@headlessui/react"

export type NavBarButtonProps = {
    expanded?: boolean
    label: string
    onClick?: () => void
    active?: boolean
    children?: JSX.Element
}

export const NavBarButton = ({
    expanded,
    label,
    onClick,
    active = false,
    children,
}: NavBarButtonProps) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`group flex h-8 flex-row items-center justify-start gap-6`}
            data-active={active}
        >
            <div
                className={`
                    group flex h-6 w-6 items-center justify-center
                    text-bonza-pale transition-all duration-75

                    group-data-[active=true]:text-bonza-primary

                    group-hover:text-bonza-primary
                `}
            >
                {children}
            </div>
            <Transition show={expanded}>
                <p
                    className={`
                        group max-w-[300px] whitespace-nowrap text-nowrap
                        text-left text-lg text-bonza-pale-t-70 transition-all
                        duration-300

                        data-[closed]:max-w-0 data-[closed]:text-bonza-dark

                        group-hover:text-bonza-pale group-hover:duration-75
                    `}
                >
                    {label}
                </p>
            </Transition>
        </button>
    )
}
