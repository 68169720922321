import LoggerService from "@/services/LoggerService"
import {
    defaultCodecOptionsIndex,
    defaultmanualJitterIndex,
    defaultNetworkBufferSizeIndex,
    defaultSampleRateIndex,
    diffuseIRDepthMessageV240124,
    getExternalIPAndPortMessage,
    getSpatialLocationsTable,
    getUUIDMessage,
    inputChannelCountPossibilities,
    inputChannelCountPossibilityIndices,
    inputDeviceSelectMessageV240124,
    latencies,
    localDirectOnOffMessageV240124,
    NetworkBufferSizes,
    outputDeviceSelectMessageV240124,
    ozoneMessages,
    ProbeMessage,
    SampleRateValues,
    setColorOrBWMessage,
    setInterfaceMessage,
    setOzoneMessage,
    setStreamQualityMessage,
    setVideoDeviceMessage,
    spatialLocationMessage,
    standaloneMessage,
    StartAudioMessageWithMostParams,
} from "@/types/AppMessage"
import { MessageEventListener } from "@/types/WebSocket"
import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react"
import { useAgentContext } from "./AgentContext"
import { settingsReducer, Settings } from "@/reducers/settingsReducer"
import { deviceReducer, Devices } from "@/reducers/deviceReducer"
import { putConnect } from "@/services/ConnectionService"
import { ConnectionResource } from "@/types/Connection"

type IncomingMessage = Record<string, any> & {
    type: string
}

type ReadyState = "disconnected" | "false" | "pending" | "true" | "error"

const expectedBonzaVersion = 250114

export const ResNames = ["Max", "Med", "Small", "VSmall"]
export const defaultResIndex: number = 2
export const ColorOpts = ["BW", "Colour"]
export const defaultBwIndex: number = 1
export const latencyStrings = [
    "lowest (64)",
    "low (128)",
    "moderate (256)",
    "fair (512)",
]
export const defaultLatenciesIndex = 1 // 128

export type LocalChannel = {
    volume: number
    pan: number
}

export type SoundCard = {
    index: number
    name: string
    inputChannels: number
    outputChannels: number
}

export type VideoDevice = {
    index: number
    name: string
}

type DeviceContextProps = {
    setConnection: React.Dispatch<
        React.SetStateAction<ConnectionResource | undefined>
    >
    developerMode: boolean
    setDeveloperMode: React.Dispatch<boolean>
    deviceReady: ReadyState
    resetDeviceAudio: () => void
    devices: Devices
    inputSoundCards: SoundCard[]
    outputSoundCards: SoundCard[]
    settings: Settings
    setSettings: (partialSettings: Partial<Settings>) => void
    setSettingsState: React.Dispatch<Partial<Settings>>
    localChannelSettings: Record<number, LocalChannel>
    setLocalVolume: (channel: number, volume: number) => void
    setLocalPan: (channel: number, pan: number) => void
}

const DeviceContext = createContext<DeviceContextProps | undefined>(undefined)

export const DeviceContextProvider = ({
    children,
}: React.PropsWithChildren) => {
    const { sendAgentMessage, addMessageListeners, socketReady } =
        useAgentContext()

    const [connection, setConnection] = useState<ConnectionResource>()

    const [developerMode, setDeveloperMode] = useState(false)

    const logger = new LoggerService("DeviceContext")
    const [deviceReady, setDeviceReady] = useState<ReadyState>("disconnected")
    const resetDeviceAudio = () => setDeviceReady("false")

    const [devices, setDevices] = useReducer(deviceReducer, {
        videoDevices: [],
        soundCards: [],
        networkInterfaces: [],
    })

    const inputSoundCards = useMemo(
        () =>
            devices.soundCards.filter(
                (soundCard) => soundCard.inputChannels > 0
            ),
        [devices.soundCards]
    )
    const outputSoundCards = useMemo(
        () =>
            devices.soundCards.filter(
                (soundCard) => soundCard.outputChannels > 0
            ),
        [devices.soundCards]
    )

    const [settings, setSettingsState] = useReducer(settingsReducer, {
        videoDevice: undefined,
        inputSoundCard: undefined,
        outputSoundCard: undefined,
        outputChannels: "0",
        inputChannelsIndex: "0",
        spatialLocation: "none",
        diffuseIRLevelIndex: "4",
        oZoneIndex: "1",
        directOnOff: "0", // convert to bool later,
        videoColorIndex: defaultBwIndex.toString(),
        videoResolutionIndex: defaultResIndex.toString(),
        userName: "",
        frameSizeIndex: defaultLatenciesIndex.toString(),
        faderPos: "",
        // --- "advanced" settings ---
        sampleRateIndex: defaultSampleRateIndex.toString(),
        networkBufferSizeIndex: defaultNetworkBufferSizeIndex.toString(),
        codecOptionsIndex: defaultCodecOptionsIndex.toString(),
        manualJitterIndex: defaultmanualJitterIndex.toString(),
        networkInterface: undefined,
    })

    const setSettings = (partialSettings: Partial<Settings>) => {
        if (partialSettings.videoDevice !== undefined) {
            // stop (index "0" if none)
            sendAgentMessage(
                new setVideoDeviceMessage(
                    partialSettings.videoDevice.index.toString()
                )
            )
            sendAgentMessage(
                // default to Colour
                new setColorOrBWMessage(1)
            )
        }
        if (partialSettings.inputSoundCard !== undefined) {
            sendAgentMessage(
                new inputDeviceSelectMessageV240124(
                    partialSettings.inputSoundCard.index
                )
            )
        }
        if (partialSettings.outputSoundCard !== undefined) {
            sendAgentMessage(
                new outputDeviceSelectMessageV240124(
                    partialSettings.outputSoundCard.index
                )
            )
        }

        if (
            partialSettings.inputSoundCard !== undefined ||
            partialSettings.outputSoundCard !== undefined ||
            partialSettings.inputChannelsIndex !== undefined ||
            partialSettings.outputChannels !== undefined ||
            partialSettings.networkBufferSizeIndex !== undefined ||
            partialSettings.sampleRateIndex !== undefined ||
            partialSettings.frameSizeIndex !== undefined
        )
            resetDeviceAudio()

        setSettingsState(partialSettings)

        const fullSettings = {
            ...settings,
            ...partialSettings,
        }

        if (connection)
            putConnect({
                identifier: connection.identifier,
                engine_ip: connection.engine_ip,
                interface_ip: connection.interface_ip,
                udp_port: connection.udp_port,
                udp_port_2: `${connection.udp_port} (${connection.port_status})`,
                port_status: connection.port_status,
                metadata: fullSettings,
            })
        else logger.warn("Connection is undefined")
    }

    const [localChannelSettings, setLocalChannelSettings] = useState<
        Record<number, LocalChannel>
    >({})

    const setLocalVolume = (channel: number, volume: number) => {
        setLocalChannelSettings((settings) => ({
            ...settings,
            [channel]: {
                pan: localChannelSettings[channel].pan ?? 100,
                volume,
            },
        }))
    }

    const setLocalPan = (channel: number, pan: number) => {
        setLocalChannelSettings((settings) => ({
            ...settings,
            [channel]: {
                volume: localChannelSettings[channel].volume ?? 50,
                pan,
            },
        }))
    }

    useEffect(
        () =>
            setLocalChannelSettings((channels) => {
                const numInputChannels =
                    inputChannelCountPossibilities[+settings.inputChannelsIndex]
                console.log("Number of input channels", numInputChannels)
                const newChannels: Record<number, LocalChannel> = {}
                for (let i = -1; i < +numInputChannels; i++) {
                    if (channels[i] !== undefined)
                        newChannels[i] = { ...channels[i] }
                    else
                        newChannels[i] = {
                            volume: 100,
                            pan: 0,
                        }
                }
                return { ...newChannels }
            }),
        [settings.inputChannelsIndex, settings.inputSoundCard]
    )

    const agentDeviceMessageListener: MessageEventListener = {
        handleMessageEvent(
            _: MessageEvent,
            eventData: IncomingMessage | null | undefined
        ) {
            if (!eventData || !eventData.type) return
            switch (eventData.type) {
                case "setAudioDeviceInfo":
                    setDevices({
                        soundCard: {
                            index: eventData.audioCount || 0,
                            name: eventData.audioName ?? "no audio",
                            inputChannels: eventData.inputChannels || 0,
                            outputChannels: eventData.outputChannels || 0,
                        },
                    })
                    logger.info(
                        `Added soundcard index ${eventData.audioCount} ${eventData.audioName}`
                    )
                    break
                case "setNICOptions":
                    {
                        const msgNetInterfaces: Array<string> = []
                        let index = 0
                        msgNetInterfaces[index++] = eventData.IF1 || ""
                        msgNetInterfaces[index++] = eventData.IF2 || ""
                        msgNetInterfaces[index++] = eventData.IF3 || ""
                        msgNetInterfaces[index++] = eventData.IF4 || ""
                        msgNetInterfaces[index++] = eventData.IF5 || ""
                        msgNetInterfaces[index++] = eventData.IF6 || ""
                        const networkInterfaces = msgNetInterfaces.filter(
                            (netInterface) => netInterface != "NOT PRESENT"
                        )
                        setDevices({
                            networkInterfaces,
                        })
                        // set NIC in connection
                        logger.info(`Added NICs ${networkInterfaces}`)
                    }
                    break
                case "setVideoDeviceInfo":
                    if (
                        eventData.videoName &&
                        eventData.videoName != "no video"
                    ) {
                        setDevices({
                            videoDevice: {
                                index: eventData.videoCount || 0,
                                name: eventData.videoName,
                            },
                        })
                        logger.info(
                            `Added videocard index ${eventData.videoCount} ${eventData.videoName}`
                        )
                    }
                    break

                case "setMIDIInputDeviceInfo":
                    logger.info(
                        `Found Midi Input Device ${eventData.inputMIDIName}`
                    )
                    break
                case "setMIDIOutputDeviceInfo":
                    logger.info(
                        `Found Midi Output Device ${eventData.outputMIDIName}`
                    )
                    break
                case "applySettingsV240124":
                    // Deprecated
                    break

                case "getUUID":
                    {
                        const uuid: string | null = eventData.UUID
                        logger.info(`UUID is ${uuid}`)
                    }
                    break
                case "standalone": {
                    const verN: number = Number(eventData.version).valueOf()
                    if (eventData.version.length == 0 || isNaN(verN)) {
                        alert("Missing Bonza version number")
                        setDeviceReady("error")
                        return
                    }
                    if (eventData.BonzaVersion < 250115) {
                        alert(
                            `Wrong Version of BonzaApp ${eventData.version}! should be ${expectedBonzaVersion} or later`
                        )
                        setDeviceReady("error")
                        return
                    }
                    const bonzaIsCoStar: boolean = eventData.IsCoSTAR
                    const envIsCoStar =
                        import.meta.env.VITE_BONZA_COSTARDEMO === "true"
                    if (envIsCoStar !== bonzaIsCoStar) {
                        console.error(`Mismatching CoSTAR flag`)
                        setDeviceReady("error")
                        return
                    }
                    break
                }
                case "setLocalSoundLevel":
                case "setLocalSoundLevelV1": {
                    // We know device is ready once we're recieving sound levels
                    setDeviceReady((ready) =>
                        ready === "pending" ? "true" : ready
                    )
                    break
                }
                // These are handled in the Bonza Context
                case "crashIndicator":
                case "tellPort":
                case "soundCardStatus":
                case "setRemoteSoundLevel":
                case "sendVideoImage":
                case "sendRemoteVideoImage":
                case "tellLatency":
                case "tellDropout":
                case "streamIsGone":
                case "streamIsHere":
                case "setJitterBuffer":
                case "headTracker":
                case "traceroute":
                    break
                default:
                    logger.todo(
                        `Unhandled incoming message ${eventData.type}: ${eventData}`
                    )
                    break
            }
        },
    }

    // More options need to be added for more devices
    const checkAndLimitSampleRate = (
        sampleRate: number,
        currscin: SoundCard,
        currscout: SoundCard
    ): number => {
        if (
            currscin.name == "MacBook Air Microphone" ||
            currscout.name == "MacBook Air Speakers"
        ) {
            if (sampleRate > 96000) {
                sampleRate = 96000
            }
        }

        return sampleRate
    }

    const pollDevice = () => {
        logger.info("Probe for tellPort")
        sendAgentMessage(new getExternalIPAndPortMessage())

        setDeviceReady((ready) => (ready === "true" ? "true" : "pending"))
    }

    useEffect(() => {
        if (!socketReady) {
            setDeviceReady("disconnected")
            return
        } else
            setDeviceReady((ready) =>
                ready === "disconnected" ? "false" : ready
            )

        logger.info("Add device message listener")
        addMessageListeners(agentDeviceMessageListener)

        logger.log("Probe for init messages")
        sendAgentMessage(new getUUIDMessage())

        sendAgentMessage(
            new setStreamQualityMessage(defaultCodecOptionsIndex.toString())
        )
        sendAgentMessage(new standaloneMessage("public", "PLACEHOLDER"))
        sendAgentMessage(new ProbeMessage())

        logger.log("Begin periodic probing")
        const interval = setInterval(pollDevice, 5000)

        return () => {
            clearTimeout(interval)
        }
    }, [socketReady])

    useEffect(() => {
        logger.info(`Device status: ${deviceReady}`)
        if (deviceReady === "true") return

        // TODO: These instead need to be cases for starting one-way audio
        if (settings.inputSoundCard === undefined) {
            logger.warn("Input sound card is undefined")
            return
        }
        if (settings.outputSoundCard === undefined) {
            logger.warn("Output sound card is undefined")
            return
        }

        // Input channels index code
        const selIndex = Number(settings.inputChannelsIndex)
        const audioChannelIndexCode =
            inputChannelCountPossibilityIndices[selIndex]

        // Outut channels
        const playbackChannels: number = Number(settings.outputChannels)
        if (playbackChannels < 1) {
            logger.warn("Available playback channels are less than 1")
            return
        }

        const frameSize: number = latencies[parseInt(settings.frameSizeIndex)]
        const sampleRateIndex: number = parseInt(settings.sampleRateIndex)

        const reqSampleRate: number = SampleRateValues[sampleRateIndex]
        const sampleRate = checkAndLimitSampleRate(
            reqSampleRate,
            settings.inputSoundCard,
            settings.outputSoundCard
        )
        if (sampleRate != reqSampleRate) {
            logger.warn(
                `reqSampleRate ${reqSampleRate} was illegal - setting to ${sampleRate}`
            )
        }

        const frameSizeSendIndex: number = parseInt(
            settings.networkBufferSizeIndex
        )
        const reqFrameSizeSend: number = NetworkBufferSizes[frameSizeSendIndex]
        let frameSizeSend: number = reqFrameSizeSend
        if (reqFrameSizeSend < frameSize) {
            logger.warn(
                `frameSizeSend ${reqFrameSizeSend} was < frameSize ${frameSize} - setting to ${frameSize}`
            )
            frameSizeSend = frameSize
        }

        logger.log("Starting Bonza audio")
        sendAgentMessage(
            new StartAudioMessageWithMostParams(
                settings.inputSoundCard.index.toString(),
                settings.outputSoundCard.index.toString(),
                audioChannelIndexCode.toString(),
                playbackChannels.toString(),
                frameSize.toString(),
                sampleRate.toString(),
                frameSizeSend.toString()
            )
        )

        if (
            getSpatialLocationsTable()
                .map((location) => location.message)
                .includes(settings.spatialLocation)
        )
            sendAgentMessage(
                new spatialLocationMessage(settings.spatialLocation)
            )
        else sendAgentMessage(new spatialLocationMessage("none"))

        const diffuseIRLevelIndex: number = Number(settings.diffuseIRLevelIndex)
        sendAgentMessage(new diffuseIRDepthMessageV240124(diffuseIRLevelIndex))

        const oZoneIndex: number = Number(settings.oZoneIndex)
        sendAgentMessage(new setOzoneMessage(ozoneMessages[oZoneIndex]))

        const directOnOff: string = settings.directOnOff
        sendAgentMessage(
            new localDirectOnOffMessageV240124(
                directOnOff === "1" ? true : false
            )
        )

        // Check video device is valid

        if (
            settings.videoDevice &&
            devices.videoDevices.findIndex(
                (device) =>
                    device.name === settings.videoDevice?.name &&
                    device.index === settings.videoDevice?.index
            )
        ) {
            sendAgentMessage(
                new setVideoDeviceMessage(settings.videoDevice.index.toString())
            )
        }

        setDeviceReady("pending")
    }, [
        deviceReady,
        settings.inputSoundCard,
        settings.outputSoundCard,
        settings.inputChannelsIndex,
        settings.outputChannels,
        settings.networkBufferSizeIndex,
        settings.sampleRateIndex,
        settings.frameSizeIndex,
        developerMode,
    ])

    useEffect(() => {
        if (devices.networkInterfaces.length === 0) return
        if (
            settings.networkInterface === undefined ||
            !devices.networkInterfaces.includes(settings.networkInterface)
        ) {
            const defaultNetworkInterface = devices.networkInterfaces[0]
            setSettings({ networkInterface: defaultNetworkInterface })
            sendAgentMessage(new setInterfaceMessage(defaultNetworkInterface))
        }
    }, [devices.networkInterfaces.length, settings.networkInterface])

    return (
        <DeviceContext.Provider
            value={{
                setConnection,
                developerMode,
                setDeveloperMode,
                deviceReady,
                resetDeviceAudio,
                devices,
                settings,
                setSettingsState,
                localChannelSettings,
                inputSoundCards,
                outputSoundCards,
                setSettings,
                setLocalVolume,
                setLocalPan,
            }}
        >
            {children}
        </DeviceContext.Provider>
    )
}

export function useDeviceContext(): DeviceContextProps {
    const ctx = useContext(DeviceContext)
    if (ctx === undefined) throw Error("Device Context is undefined")
    return ctx
}

export default DeviceContext
